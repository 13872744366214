// import {
//   FETCH_DATA_RESUME_CASH, SET_DATA_RESUME_CASH, FETCH_OPENED_TICKETS, SET_LOGINS, ADD_LOGINS, ADD_OPENED_TICKETS, UPDATE_TICKET, REMOVE_TICKET, 
//   SET_OPENED_TICKETS, SET_CLOSED_TICKETS, FETCH_TICKETS_STATS, SET_TICKETS_STATS, SET_FOREGROUND, SET_CC, SET_VERSION_DB, SET_CC_VEHICLES
// } from "./actionTypes"


import {
  FETCH_DATA_RESUME_CASH, SET_DATA_RESUME_CASH,
  SET_LOGINS, ADD_LOGINS,

  // TICKET ABIERTOS
  FETCH_OPENED_TICKETS, SET_OPENED_TICKETS, ADD_OPENED_TICKETS, UPDATE_OPENED_TICKETS, REMOVE_OPENED_TICKET,

  // TICKETS CERRADOS
  FETCH_CLOSED_TICKETS, ADD_CLOSED_TICKET, SET_CLOSED_TICKETS, UPDATE_CLOSED_TICKETS, REMOVE_CLOSED_TICKET,

  FETCH_TICKETS_STATS, SET_TICKETS_STATS, SET_FOREGROUND, SET_CC, SET_LICENCE, SET_APPCONFIG, SET_LOCATION, SET_VERSION_DB, SET_CC_VEHICLES, SET_MANAGER_DATA, SET_INOUT, ADD_INOUT


} from "./actionTypes"


export const fetchDataResumeCash = () => ({
  type: FETCH_DATA_RESUME_CASH,
})

export const setDataResumeCash = (data) => {
  return {
    type: SET_DATA_RESUME_CASH,
    payload: data
  };
};

export const setVersionDB = (data) => {
 
  return {
    type: SET_VERSION_DB,
    payload: data
  };
};



// TICKETS ABIERTOS
export const fetchOpenedTickets = () => ({
  type: FETCH_OPENED_TICKETS,
})

export const setOpenedTickets = (data) => {
  return {
    type: SET_OPENED_TICKETS,
    payload: data
  };
};

export const addOpenedTickets = (data) => {
  return {
    type: ADD_OPENED_TICKETS,
    payload: data
  };
}

export const updateOpenedTickets = (data) => {
  return {
    type: UPDATE_OPENED_TICKETS,
    payload: data
  };
}

export const removeOpenedTicket = (data) => {
  return {
    type: REMOVE_OPENED_TICKET,
    payload: data
  };
}

// TICKETS CERRADOS
export const fetchClosedTickets = () => ({
  type: FETCH_CLOSED_TICKETS,
})



export const setClosedTickets = (data) => {
  return {
    type: SET_CLOSED_TICKETS,
    payload: data
  };
}

export const addClosedTickets = (data) => {
  return {
    type: ADD_CLOSED_TICKET,
    payload: data
  };
}

export const updateClosedTickets = (data) => {
  return {
    type: UPDATE_CLOSED_TICKETS,
    payload: data
  };
}

export const removeClosedTicket = (data) => {
  return {
    type: REMOVE_CLOSED_TICKET,
    payload: data
  };
}




export const fetchTicketsStats = () => ({
  type: FETCH_TICKETS_STATS,
})

export const setTicketsStats = (data) => {
  return {
    type: SET_TICKETS_STATS,
    payload: data
  };
}

export const setForeground = (data) => {
  return {
    type: SET_FOREGROUND,
    payload: data
  };
}


export const setLogins = (data) => {

  return {
    type: SET_LOGINS,
    payload: data
  };
}

export const addLogins = (data) => {
  return {
    type: ADD_LOGINS,
    payload: data
  };
}

export const setCC = (data) => {
  return {
    type: SET_CC,
    payload: data
  };
}



export const setCCVehicles = (data) => {

  return {
    type: SET_CC_VEHICLES,
    payload: data
  };
}

export const setAppConfig = (data) => {
  return {
    type: SET_APPCONFIG,
    payload: data
  };
}


export const setInout = (data) => {
  return {
    type: SET_INOUT,
    payload: data
  };
}

export const addInout = (data) => {
  return {
    type: ADD_INOUT,
    payload: data
  };
  
}

// Agrega la acción resetInout
export const resetInout = () => ({
  type: 'RESET_INOUT',
});

