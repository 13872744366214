function PlateFormat(input) {
  if (!input) return "";
  // Inserta un espacio entre una secuencia de letras seguida de dígitos
  let result = input.replace(/([A-Za-z]+)(\d+)/g, "$1 $2");
  // Inserta un espacio entre una secuencia de dígitos seguida de letras
  result = result.replace(/(\d+)([A-Za-z]+)/g, "$1 $2");
  // Elimina espacios dobles y recorta espacios al inicio y final
  result = result.replace(/\s+/g, " ").trim();
  return result;
}

export default PlateFormat;
