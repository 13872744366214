import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Row,
  Container,
  CardTitle,
  CardFooter,
  CardText,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { firestore } from "../../firebase";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";

import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme
import "./agGridCustomStyle.css";



import numeral from "numeral";
import spanishText from "./agGridLocaleES";

import PlateFormat from "../../components/PlateFormat";

//please asign your license key to the licenseKey constant as a string above

const TableInOut = (props) => {
  const lista = useSelector((state) => state.data.inout);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image, placa = '') => {
    setSelectedImage(image);
    setModalOpen(placa);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState(lista);
  const GridRef = useRef();

  useEffect(() => {
    setRowData(props.data);
  }, [props.data]);

  const onGridReady = useCallback((params) => {
    // Autoajustar todas las columnas a su contenido
    // const allColumnIds = params.api.getColumns().map((col) => col.getId());
    //   params.api.autoSizeColumns(allColumnIds);

    // Ajustar la columna "Detalle" al contenido y permitir expansión
    setTimeout(() => {
      const detailColumn = params.api.getColumn("detail");
      if (detailColumn) {
        params.api.autoSizeColumn("detail");
        // params.columnApi.setColumnFlex("detail", 1); // Permitir expansión si hay espacio
      }
    }, 1100);
  }, []);

  const onModelUpdated = useCallback((params) => {
    return;
    if (!params.api.isAnyFilterPresent()) {
      const savedState = localStorage.getItem("columnState");
      if (savedState) {
        try {
          const stateToRestore = JSON.parse(savedState);
          params.columnApi.api.applyColumnState(stateToRestore);
        } catch (e) {
          console.error("Error 222 al restaurar el estado de las columnas:", e);
        }
      }
    }
  }, []);

  function base64ToImage(base64, placa) {
    if (!base64) return "Sin imagen"; // Si no hay datos, muestra un mensaje en la celda
    return (
      <img
        src={`data:image/jpeg;base64,${base64}`}
        alt="imagen"
        style={{ width: "100px", height: "auto" }}
        onClick={() => openModal(`data:image/png;base64,${base64}`, placa)}
      />
    );
  }

  function renderEntryExitIcon(value) {
    if (value == 1) {
      return <i className="fas fa-arrow-right text-success"></i>; // Entrada (Flecha verde hacia la derecha)
    }
    if (value == 0) {
      return <i className="fas fa-arrow-left text-danger"></i>; // Salida (Flecha roja hacia la izquierda)
    }
    if (value == 2) {
      return <i className="fas fa-flag white"></i>; // Salida Manual (Bandera azul)
    }
    return <i className="dripicons-warning text-muted"></i>; // Desconocido
  }
  // Función para formatear la fecha
  function formatDate(dateString) {
    if (!dateString) return "";
    // Parsear la fecha de entrada
    const date = new Date(dateString);
    // Formatear la fecha a d/m/Y HH:mm
    const formattedDate =
      date.getDate().toString().padStart(2, "0") +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0");
    return formattedDate;
  }

  function formatPrice(price) {
    return numeral(price).format("$0,0.00");
  }

  function formatType(type) {
    switch (type) {
      case "hour":
        return "xHora";
      case "stay":
        return "xEstadia";
      case "tn":
        return "xTurno";
      default:
        return "--";
    }
  }

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState([
    // {
    //   field: "id",
    //   sortable: true,
    //   filter: true,
    //   headerName: "ID",
    //   width: 60,
    //   minWidth: 60,
    //   maxWidth: 60,
    // },

    // {
    //   field: "presence",
    //   sortable: true,
    //   filter: true,
    //   valueFormatter: (params) => {
    //     const val = params.value;
    //     if (val == 1) {
    //       return "Entrada";
    //     }
    //     if (val == 0) {
    //       return "Salida";
    //     }
    //     if (val == 2) {
    //       return "Salida Manual";
    //     }

    //     return " - ";
    //   },
    //   headerName: "Tipo",
    // },

    {
      field: "presence",
      headerName: "E/S",
      cellRenderer: (params) => renderEntryExitIcon(params.value),
      width: 80,
      minWidth: 80,
      maxWidth: 80,
    },

    {
      field: "date",
      sortable: true,
      headerName: "Fecha",
      valueFormatter: (params) => formatDate(params.value),
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },

    {
      field: "detail",
      headerName: "Detalle",
      flex: 1, // 🔥 Se expandirá solo si hay espacio disponible
      minWidth: 200, // 🔥 Evita que sea demasiado pequeña
      //   wrapText: true, // 🔥 Permite que el texto se divida en múltiples líneas
      //   autoHeight: true, // 🔥 Ajusta la altura automáticamente
      tooltipValueGetter: (params) => params.value || "", // Muestra el texto completo al pasar el mouse
    },

    {
      field: "picture",
      sortable: true,
      filter: true,
      headerName: "Imagen",
      cellRenderer: (params) =>
        base64ToImage(params.value, PlateFormat(params.data.plate)),
    },

    {
      field: "category_name",
      sortable: true,
      filter: true,
      headerName: "Categoria",
    },

    {
      field: "plate",
      sortable: true,
      filter: true,
      headerName: "Matrícula",
      //   valueFormatter: (params) => "#" + params.value.toUpperCase(),
      width: 150,
      minWidth: 150,
      maxWidth: 200,
    },

    {
      field: "user_fullname",
      sortable: true,
      filter: true,
      headerName: "Operador",
    },

    // {
    //   field: "type",
    //   sortable: true,
    //   filter: true,
    //   headerName: "Tipo",
    //   valueFormatter: (params) => formatType(params.value),
    // },
    // {
    //   field: "description",
    //   sortable: true,
    //   filter: true,
    //   headerName: "Descripcion",
    //   tooltipValueGetter: (params) =>
    //     params.value === null ? "" : `${params.value}`,
    // },

    // {
    //   field: "end_date",
    //   sortable: true,
    //   headerName: "Fecha Fin",
    //   valueFormatter: (params) => formatDate(params.value),
    // },

    // {
    //   field: "value",
    //   headerName: "Valor",
    //   valueFormatter: (params) => formatPrice(params.value),
    // },
    // // { field: "paid", headerName: "Pagado", valueFormatter: (params) => formatPrice(params.value) },
    // // { field: "place_name", headerName: "Ubicación" },
    // {
    //   field: "canceled",
    //   sortable: true,
    //   headerName: "Cancelado",
    //   valueFormatter: (params) => (params.value === 1 ? "Si" : ""),
    // },
    // {
    //   field: "canceled_details",
    //   sortable: true,
    //   headerName: "Motivo de cancelación",
    //   tooltipValueGetter: (params) =>
    //     params.value === null ? "" : `${params.value}`,
    // },
  ]);

  // No es necesario usar useState para gridOptions ya que no se espera que cambie dinámicamente
  const gridOptions = {
    rowClassRules: {
      // Aplica la clase 'row-canceled' cuando el campo 'canceled' es igual a 1
      "row-canceled": (params) => params.data.canceled === "1",
    },
    defaultColDef: {
      minWidth: 150,
      filter: true,
      sortable: true,
      resizable: true,
      flex: 1,
      // editable: true,
    },

    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columnas",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true, // Oculta la sección "Grupos"
            suppressValues: true, // Oculta la sección "Valores"
            suppressPivots: true, // Oculta la opción de pivotar
            suppressPivotMode: true, // Oculta el interruptor de "Modo pivote"
            // Puedes establecer otras opciones aquí si es necesario
          },
        },
        {
          id: "filters",
          labelDefault: "Filtros",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
    },
    localeText: spanishText, // Asegúrate de que este texto esté definido o importado correctamente
  };

  const onColumnVisible = useCallback((params) => {
    // Guardar el estado de las columnas cada vez que cambia la visibilidad
    console.log("guardamos");
    const state = params.api.getColumnState();
    localStorage.setItem("columnState", JSON.stringify(state));
  }, []);

  return (
    // Container
    <React.Fragment>
      <div
        className="ag-theme-alpine"
        style={{ height: "calc(100vh - 200px)", width: "100%" }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          sideBar={gridOptions.sideBar}
          onGridReady={onGridReady}
          onModelUpdated={onModelUpdated}
          rowClassRules={{
            "row-canceled": (params) => params.data.presence === 2,
          }}
          onColumnVisible={onColumnVisible}
          ref={GridRef}
        />
      </div>

      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        size="xs"
        centered
        keyboard={true}
        backdrop={true}
      >
       
        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ padding: 0 }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="ticket full-size"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "80vh",
                width: "auto",
                height: "auto",
                zoom: 1.5,
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.data.inout, // data es la propiedad en el estado de Redux donde almacenas los datos
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableInOut);
