import React, {useEffect, useState } from 'react';

import {
    Container, Row, Col, Card,
    CardBody,
    CardText,
    CardTitle,

    Collapse,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { connect } from 'react-redux';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";



import Table from './table';
// import tableCustomers from './tableCustomers';
import TableVehicles from './tableVehicles';
import classnames from "classnames";
import TableCustomers from './tableCustomers';
import CC_Stats from './cc_stats';




const CC = (props) => {

    const version = props.versionDB;

    document.title = "Mensuales | TicketControl - Monitor";

    const [activeTab2, setactiveTab2] = useState("1");

    const toggle2 = (tab) => {
        if (activeTab2 !== tab) {
            setactiveTab2(tab);
        }
    };

    const from_version = "1.2.49";
    function compareVersions(v1, v2) {
        try {
        
        const v1Parts = v1.split('.').map(Number);
        const v2Parts = v2.split('.').map(Number);

        for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
            const v1Part = v1Parts[i] || 0; // Si no hay más partes, se considera como 0
            const v2Part = v2Parts[i] || 0;

            if (v1Part < v2Part) {
                return true;
            }
            if (v1Part > v2Part) {
                return false;
            }
        }

        } catch (error) {
            console.error('Error al comparar versiones:', error.message);
            console.log('v1', v1);
            console.log('v2', v2);
            return false;
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TicketControl" breadcrumbItem="Mensuales" />
                    {!compareVersions(from_version, version) && <Table />}


                    {/* NUEVA VERSION > 1.2.50 */}
                    {compareVersions(from_version, version) &&  
                    <>

                        <Row>
                            <Col>
                                <CC_Stats />
                            </Col>
                        </Row>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab2 === "1",
                                    })}
                                    onClick={() => {
                                        toggle2("1");
                                    }}
                                >
                                    <i className="dripicons-home me-1 align-middle"> </i>{" "}
                                    Clientes
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab2 === "2",
                                    })}
                                    onClick={() => {
                                        toggle2("2");
                                    }}
                                >
                                    <i className="dripicons-user me-1 align-middle"></i>{" "}
                                    Vehículos
                                </NavLink>
                            </NavItem>


                        </Nav>

                        <TabContent activeTab={activeTab2} className="p-3 text-muted">
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <CardText className="mb-0">
                                            <Row>
                                                <TableCustomers />
                                            </Row>
                                        </CardText>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <CardText className="mb-0">
                                            <Row>
                                                <TableVehicles />
                                            </Row>
                                        </CardText>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>



                    </>}

                </Container>
            </div>
        </React.Fragment>
    );
};




const mapStateToProps = (state) => {
    return {
        versionDB: state.data.versionDB,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CC);



// export default CC;


