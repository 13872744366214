// ESTA TABLA ES PARA LA VERSIONES A PARTIR DE LA 1.2.50

import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
// import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { map } from 'lodash';
import numeral from 'numeral'
import moments from 'moment'
moments.locale('es')



const TableCC = (props) => {
   
    const columns = [

        {
            name: <span className='font-weight-bold fs-13'>ID</span>,
            selector: row => row.id,
            sortable: true,
            width: "90px",
            cellExport: row => row.id,
        },
        {
            name: <span className='font-weight-bold fs-13'>Cliente</span>,
            selector: row => <span style={{ fontWeight: 700 }}>{row.fullName.toUpperCase()}</span>,
            sortable: true,
            wrap: true,
            width: "250px",
            cellExport: row => row.fullName,

        },
        {
            name: <span className='font-weight-bold fs-13'>Vehículos</span>,
            selector: row => row.vehicle_count,
            sortable: true,
            wrap: true,
            cellExport: row => row.plate,
        },
        {
            name: <span className='font-weight-bold fs-13'>Matrículas</span>,
            selector: row => row.plates ? row.plates.split('|').map(plate => `#${plate}`).join(' - ') : 'Sin Vehículos',
            sortable: true,
            wrap: true,
            cellExport: row => row.plates ? row.plates.split('|').map(plate => `#${plate}`).join(' - ') : 'Sin Vehículos'
        },
        {
            name: <span className='font-weight-bold fs-13'>Balance</span>,
            selector: row => (<span><strong>{numeral(row.balance).format('$ 0,0')}</strong></span>),
            sortable: true,
            cellExport: row => row.balance,
        },
        {
            name: <span className='font-weight-bold fs-13'>Prox. Cargo</span>,
            selector: row => moments(row.next_movement).format('DD/MM/YYYY'),
            sortable: true,
            cellExport: row => row.next_movement,   
        },
       
    ];


    if (!props.cc) {
        return (<div>No hay clientes por el momento</div>)
    }

   
    let items = []

    
    props.cc.forEach((cc, index) => {

        // filtramos todos los elementos que no contengan timelapse_id
        if (!cc['timelapse_id']) {
            const item = {
                id: cc['cc_id'],
                fullName: cc['fullName'],
                category_name: cc['category_name'],
                plates: cc['plates'],
                vehicle_count: cc['vehicle_count'],
                floor_name: cc['place_name'] ? cc['floor_name'] + " - " + cc['place_name'] : 'Sin asignar',
                timelaspes_label: cc['timelaspes_label'],
                balance: cc['balance'],
                next_movement: cc['next_movement'],
                status: cc['balance']
            }

            items.push(item)
        }

    })

    const data = items



    const tableData = {
        columns,
        data
      };

      const customMessages = {
        // Mensajes del encabezado de la tabla
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Descargar CSV',
          print: 'Imprimir',
          viewColumns: 'Ver columnas',
          filterTable: 'Filtrar tabla',
        },
        // Otros mensajes personalizados que desees cambiar
        // ...
      };

    return (
      <div>
        {/* Input de búsqueda */}

        <DataTableExtensions
          {...tableData}
          exportHeaders={false}
          filterPlaceholder="Filtrar"
        >
          <DataTable
            
            title={"Mensuales (" + String(data.length) + ")"}
            // columns={columns}
            // data={data}
            pagination
            highlightOnHover
            paginationPerPage={25}
            className="data-table"
            // noHeader={true}
            paginationDefaultPage={1}
            paginationComponentOptions={{
              rowsPerPageText: "Filas por página:",
              rangeSeparatorText: "de",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
              
              toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
              },
            }}
            customMessages={customMessages}
            // subHeader
            // subHeaderComponent={
            //     <FilterComponent
            //       onFilter={handleFilterChange}
            //       onClear={handleClear}
            //       filterText={filterText}
            //     />
            //   }
            // paginationResetDefaultPage={resetPaginationToggle}
            noDataComponent={
              <div className="p-4 text-center">No hay datos</div>
            }
            persistTableHead
          />
        </DataTableExtensions>
      </div>
    );
};

const mapStateToProps = (state) => {
    return {
        cc: state.data.cc
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableCC)